import React from 'react';
import { graphql, StaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Fade } from 'react-reveal'

//components
import Counter from "../counter"

const HonorsAchievements = ({ data }) => {
    const countOnUs = data.countOnUs
    const clutches = data.clutches
    const counters = countOnUs.frontmatter.achievements.values.group
    return (
        <section>
            <Fade bottom distance="60px" delay={300}>
                <div className="py-60 md:pb-10 lg:py-20 xl:pt-1-40 xl:pb-100 container mx-auto">
                    <div className="lg:px-1-1 mx-auto flex flex-wrap count-contain">
                        <div className="pb-4-1 sm:pb-6 md:pb-6-6 lg:pb-35 text-left sm:text-center justify-between mx-auto">
                            <Fade bottom distance="60px" delay={300}>
                                <h3 className="pb-1-2 lg:pb-5 font-poppings-bold text-rp1 lg:text-h3 text-center text-term-primary">
                                    Honors & Achievements </h3>
                            </Fade>
                            <Fade bottom distance="60px" delay={300}>
                                <p className="md:px-10 lg:px-5 text-p4 lg:text-p2 font-worksans-normal text-term-primary count-text text-center">
                                    We have a track record for excellence - it's why so many of our clients come back and refer their closest friends. They trust us with their business and we hope you'll do the same.
                                </p>
                            </Fade>
                        </div>
                        <Fade bottom distance="60px" delay={300}>
                        <div className="w-full flex flex-wrap justify-between">
                            {counters.map((counter, index) =>
                                <div key={index} className="mb-3-3 pr-3 mms:mb-6-6 sm:mb-0  w-full mms:w-1/3  sm:w-auto flex flex-wrap">
                                    <div className="flex mms:justify-center md:justify-start">
                                    <Fade bottom distance="60px" delay={300}>
                                        <div className="mr-2-2 lg:mr-1-2 w-50 h-50 lg:w-55 lg:h-55">
                                            <img src={counter.svg.publicURL} className="" alt="decodeup-projects-icon">
                                            </img>
                                        </div>
                                        </Fade>
                                        <div>
                                        <Fade bottom distance="60px" delay={300}>
                                            <h2 className="lg:mb-0-2 text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-cyan">
                                                <span>{counter.count} {"+"}</span>
                                            </h2>
                                            </Fade>
                                            <Fade bottom distance="60px" delay={300}>
                                            <p className="text-term-primary font-poppings-bold text-p4 lg:text-p2">
                                                {counter.title}
                                            </p>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        </Fade>
                    </div>
                    <div className="pt-50 md:pt-10 lg:pt-71 award-list-main mx-auto flex flex-wrap justify-center mms:justify-between">
                    <Fade bottom distance="60px" delay={300}>
                        <div className="w-full flex flex-wrap mms:justify-between md:justify-center">

                            {clutches.frontmatter.clutches.map(({ group: clutch }, i) => (
                                <div key={i} className="md:p-4 lg:p-0 w-1/3 md:w-1/4 lg:w-1/6 mb-3-3 md:mb-0 text-center aa">
                                    <div className="mx-auto w-100 h-100 md:w-1-25 md:h-1-25 text-center">
                                        <GatsbyImage className="object-cover" image={getImage(clutch.image)} alt={clutch.alt || `${clutch.name} | DecodeUp Clutch`} />
                                    </div>
                                    <p className="lg:px-2 text-term-primary font-worksans-bold text-p4 lg:text-p2">
                                        {clutch.name}
                                    </p>
                                </div>
                            ))}
                        </div>
                        </Fade>
                    </div>
                </div>
                </Fade>
        </section>
    )
}

export default props => (
    <StaticQuery
        query={graphql`
        query {
            clutches: markdownRemark(fileAbsolutePath: {regex: "/clutches.md/"}) {
                frontmatter {
                    title
                    clutches {
                        group {
                            name
                            alt
                            image {
                                childImageSharp {
                                    gatsbyImageData(
                                        placeholder: BLURRED
                                    )
                                }
                            }
                        }
                    }
                }
            }
            countOnUs: markdownRemark(fileAbsolutePath: {regex: "/achievements-count-on-us.md/"}) {
                frontmatter {
                    achievements {
                        description
                        values {
                            group {
                                count
                                title
                                svg {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
        }
    `}
        render={(data) => (
            <HonorsAchievements data={data} {...props} />
        )}
    />
)